<template>
  <div v-if="!isMobileScreen">
    <div class="container-main casino-container-user-register csn-registration">
      <div class="container">
        <div>
          <div class="row csn-registration-upper-container">
            <div class="col-md-4 csn-registration-form-container">
              <div class="box csn-registration-form-box">
                <RegistrationForm formId="registration-desktop-form" />
              </div>
            </div>
            <div class="col-md-4 csn-registration-banner-container">
              <img
                class="banner-img"
                src="@/assets/images/user-register-bonus-banner.png"
              />
            </div>
            <div class="col-md-4 csn-registration-notification-container">
              <div class="box game-notifications">
                <GameNotifications />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <RegistrationBox />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import RegistrationBox from './components/RegistrationBox'
import RegistrationForm from './components/RegistrationForm'
import GameNotifications from '@/components/GameNotifications'
import { REGISTRATION, Module, IS_MOBILE_SCREEN } from '@/constants'
import { dest } from '@/helpers'

export default {
  name: REGISTRATION,
  components: {
    RegistrationBox,
    RegistrationForm,
    GameNotifications,
  },
  computed: {
    ...mapGetters({ isMobileScreen: dest([Module.DEVICE, IS_MOBILE_SCREEN]) }),
  },
}
</script>
