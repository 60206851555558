<template>
  <div
    class="container-main casino-container-user-register-box csn-registration-box"
  >
    <div class="container">
      <div class="row csn-registration-box-data-container">
        <div class="col-md-4 csn-registration-box-data-column">
          <div class="box-description">
            <img
              src="@/assets/images/register/register-box-1.png"
              alt="register-box-1"
            />
            <h4>{{ t('left_title') }}</h4>
            <ul>
              <li>
                <span class="casino-icon casino-icon-checkbox-ticked"></span>
                {{ t('left_text_1') }}
              </li>
              <li>
                <span class="casino-icon casino-icon-checkbox-ticked"></span>
                {{ t('left_text_2') }}
              </li>
              <li>
                <span class="casino-icon casino-icon-checkbox-ticked"></span>
                {{ t('left_text_3') }}
              </li>
            </ul>
            <img
              src="@/assets/images/register/register-box-1-content.png"
              alt="register-box-1-content"
            />
          </div>
        </div>
        <div class="col-md-4 csn-registration-box-data-column">
          <div class="box-description">
            <img
              src="@/assets/images/register/register-box-2.png"
              alt="register-box-2"
            />
            <h4>{{ t('middle_title') }}</h4>
            <ul>
              <li>
                <span class="casino-icon casino-icon-checkbox-ticked"></span>
                {{ t('middle_text_1') }}
              </li>
              <li>
                <span class="casino-icon casino-icon-checkbox-ticked"></span>
                {{ t('middle_text_2') }}
              </li>
              <li>
                <span class="casino-icon casino-icon-checkbox-ticked"></span>
                {{ t('middle_text_3') }}
              </li>
            </ul>
            <img
              src="@/assets/images/register/register-box-2-content.png"
              alt="register-box-2-content"
              class="bottom-position register-box-2-content"
            />
          </div>
        </div>
        <div class="col-md-4 csn-registration-box-data-column">
          <div class="box-description">
            <img
              src="@/assets/images/register/register-box-2.png"
              alt="register-box-2"
            />
            <h4>{{ t('right_title') }}</h4>
            <ul>
              <li>
                <span class="casino-icon casino-icon-checkbox-ticked"></span>
                {{ t('right_text_1') }}
              </li>
              <li>
                <span class="casino-icon casino-icon-checkbox-ticked"></span>
                {{ t('right_text_2') }}
              </li>
              <li>
                <span class="casino-icon casino-icon-checkbox-ticked"></span>
                {{ t('right_text_3') }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { REGISTRATION_BOX } from '@/constants'

export default {
  name: REGISTRATION_BOX,
  computed: {
    t() {
      return this.$createComponentTranslator(REGISTRATION_BOX)
    },
  },
}
</script>
